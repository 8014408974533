import { Container, Row, Col } from 'react-bootstrap'
import Carousel from "./../components/Carousel"
import classes from './css/Gallery.module.scss'
import { countries } from "./../data/gallery.js"
import Light from './../img/light.jpg'
import Motion from './../img/motion.jpg'
import Disclose from './../img/disclose.jpg'
import Reality from './../img/reality.jpg'
import Walking from './../img/walking.jpg'
import Illusion from './../img/illusion.jpg'

function Gallery() {
  return (
    <Container fluid className="p-0">
      <div className={classes.HeaderImage + " " + classes.HeaderBlock}>
        <div className={classes.TextPhoto}>
          <Container><h1 className="text-center">Gallery</h1></Container>
        </div>
      </div>
      <Container fluid className={classes.Feature}>
      <Container>
          <Row className="m-0 p-0">
            <Col lg={4} className="m-0 p-0">
              <div className={classes.ImageBlock}> 
              <img src={Motion} alt="Cars on freeway blurred by motion with city backdrop" className="w-100 d-block center-block mx-auto" />
                <div className={classes.QuotePhoto}>
                  <p>"Photography takes an instant out of time, altering life by holding it still."<br></br><em>-- Dorothea Lange</em></p>
                </div>
              </div>
            </Col>
            <Col lg={4} className="m-0 p-0">
              <div className={classes.ImageBlock}>
                <img src={Light} alt="Light shining through trees" className="w-100 d-block center-block mx-auto" />
                <div className={classes.QuotePhoto}>
                  <p>"Photographs are just light and time."<br></br> -- Aza Holmes</p>
                </div>
              </div>
            </Col>
            <Col lg={4} className="m-0 p-0"s>
            <div className={classes.ImageBlock}> 
              <img src={Disclose} alt="Light shining through trees" className="w-100 d-block center-block mx-auto" />
              <div className={classes.QuotePhoto}>
                <p>"The painter constructs, the photographer discloses."<br></br><em>-- Susan Sontag</em></p>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className={classes.Wrapper}>
        <Row>
          <Col lg={9}>
            <Container className={classes.Carousel + " p-0"}>
              <Carousel images={countries} />
            </Container>
          </Col>
          <Col lg={3} className="row d-flex d-block center-block my-auto p-0">
            <div className={classes.HaikuWrapper}>
              <div className={classes.Haiku}>
                <p>A world of dew,<br></br>And within every dewdrop<br></br>A world of struggle.<br></br>-- Kobayashi Issa</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={classes.Feature + " pb-5 mb-5"}>
      <Container>
          <Row className="m-0 p-0">
            <Col lg={4} className="m-0 p-0">
              <div className={classes.ImageBlock}> 
              <img src={Reality} alt="One palm tree over water" className="w-100 d-block center-block mx-auto" />
                <div className={classes.QuotePhoto}>
                  <p>"In photography, there is a reality so subtle that it becomes more real than reality."<br></br><em>-- Alfred Stieglitz</em></p>
                </div>
              </div>
            </Col>
            <Col lg={4} className="m-0 p-0">
              <div className={classes.ImageBlock}>
                <img src={Walking} alt="Motion blur of people walking in city" className="w-100 d-block center-block mx-auto" />
                <div className={classes.QuotePhoto}>
                  <p>"One advantage of photography is that it's visual and can transcend language."<br></br> -- Lisa Kristine</p>
                </div>
              </div>
            </Col>
            <Col lg={4} className="m-0 p-0"s>
            <div className={classes.ImageBlock}> 
              <img src={Illusion} alt="Bubble with house reflection" className="w-100 d-block center-block mx-auto" />
                <div className={classes.QuotePhoto}>
                <p>"Photography, as we all know, is not real at all. It is an illusion of reality with which we create our own private world."<br></br><em>-- Arnold Newman</em></p>
              </div>
            </div>
            </Col>
          </Row>
          <div className="mb-5 pb-5"></div>
        </Container>
      </Container>
    </Container>
  );
}

export default Gallery;