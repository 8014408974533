import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Gallery from "./pages/Gallery"
import Quotes from "./pages/Quotes"
import QuotesTags from "./pages/QuotesTags"
import Layout from "./pages/Layout"
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={ <Home/> } />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={ <Gallery /> } />
            <Route path="/quotes" element={<Quotes />} />
            <Route path="/quotes/:id" element={<QuotesTags />} />
          </Route>
        </Routes>
      </BrowserRouter>
  )
}

export default App;
