import { Container, Row, Col } from 'react-bootstrap'
import classes from './css/About.module.scss'
import Avatar from './../img/avatar.png'

function About() {
  return (
    <Container fluid className={classes.Wallpaper + " p-0"}>
      <Container fluid className={classes.AboutTop}>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className={classes.Speech}>
                <h3>Hi there! I’m Julia.</h3>
                <h5> I’m a web developer in London, Ontario, Canada.<h5></h5>I enjoy turning website designs and innovative ideas into user-friendly and elegant sites.</h5>
              </div>
            </Col>
            <Col md={6}>
              <img src={Avatar} alt="JD avatar" className={classes.Photo + " d-block center-block mx-auto"} />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mb-5">
        <Row>
          <Col md={10} className={classes.Skills + " mx-auto"}>
            <h1 className="text-center mt-5 pt-5">About</h1>
            <div className={classes.Pink + " " + classes.Paper}>
              <div className={classes.TapeSection}></div>
                <table>
                <h4>Skills</h4>
                  <tr>
                    <td>Full Stack Web Development</td>
                  </tr>
                  <tr>
                    <td>Design & Layout</td>
                  </tr>
                  <tr>
                    <td>Technical Writing</td>
                  </tr>
                  </table>
              <div className={classes.TapeSection}></div>
            </div>
            <div className={classes.Blue + " " + classes.Paper}>
              <div className={classes.TopTape}></div>
              <table>
              <h4>Technologies/Frameworks</h4>
                <tr>
                  <td>React.js</td>
                  <td>PHP</td>
                </tr>
                <tr>
                  <td>WordPress</td>
                  <td>Python</td>
                </tr>
                <tr>
                  <td>JavaScript</td>
                  <td>C#</td>
                </tr>
                <tr>
                  <td>R</td>
                  <td>TypeScript</td>
                </tr>
                <tr>
                  <td>Angular</td>
                  <td>Vue.js</td>
                </tr>
                <tr>
                  <td>CSS & HTML</td>
                  <td>LAMP</td>
                </tr>
                <tr>
                  <td>NGINX</td>
                  <td>GitFlow</td>
                </tr>
                <tr>
                  <td>CI/CD</td>
                  <td>Linux (Debian, Ubuntu)</td>
                </tr>
                </table>
              </div>
          </Col>
        </Row>
        
      </Container>
    </Container>
  );
}

export default About;