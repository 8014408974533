import "./css/pagination.scss"
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg'
import PropTypes from "prop-types"
import { nanoid } from "nanoid"
import usePagination, { DOTS } from './../hooks/usePagination'
import { Container, Row, Col } from 'react-bootstrap'

function Pagination({
  onPageChange,
  onPageSizeOptionChange,
  totalCount,
  currentPage,
  pageSize,
  pageSizeOptions,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Container className="p-0 m-0">
      <Row>
        <Col sm={6} className="d-flex justify-content-start my-auto"> 
      <ul
        className="wrapper"
        // Do not modify the aria-label below, it is used for Hatchways automation.
        aria-label="Blog post pagination list"
      >
        <li className="paginationItem">
          <button
            type="button"
            className="arrowButton left"
            // Do not modify the aria-label below, it is used for Hatchways automation.
            aria-label="Goto previous page"
            onClick={onPrevious}
            disabled={currentPage === 1} // change this line to disable a button.
          >
            <CgChevronLeft />
          </button>
        </li>

        {paginationRange.map((pageNumber) => {
          const key = nanoid();

          if (pageNumber === DOTS) {
            return (
              <li key={key} className="dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={key}
              className="paginationItem"
              aria-current={pageNumber === currentPage ? "page" : "false"} // change this line to highlight a current page.
            >
              <button
                type="button"
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}

        <li className="paginationItem">
          <button
            type="button"
            className="arrowButton right"
            onClick={onNext}
            disabled={currentPage === lastPage}
          >
            <CgChevronRight />
          </button>
        </li>


          </ul>
          </Col>
        <Col sm={6} className="d-flex justify-content-end align-items-baseline">
          <select
            className="selectPage"
            value={pageSize}
            onChange={(e) => onPageSizeOptionChange(Number(e.target.value))}
          >
            {pageSizeOptions.map((size) => (
              <option key={size} defaultValue={pageSize === size} value={size}>
                {size} per page
              </option>
            ))}
            </select>
          </Col>
      </Row>
    </Container>
  );
}

Pagination.propTypes = {
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.instanceOf(Array),
  onPageChange: PropTypes.func,
  onPageSizeOptionChange: PropTypes.func,
};

Pagination.defaultProps = {
  totalCount: 0,
  currentPage: 1,
  pageSize: 1,
  pageSizeOptions: [15, 25, 50, 100],
  onPageChange: () => {},
  onPageSizeOptionChange: () => {},
};

export default Pagination;