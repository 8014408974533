import { Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from "react-router-dom"
import classes from './css/Quotes.module.scss'
import Cloud from './../img/cloud-hills.jpg'
import LeafA from './../img/leaf-1.jpg'
import LeafB  from './../img/leaf-2.jpg'
import LeafC from './../img/leaf-3.jpg'
import WaterA from './../img/water-1.jpg'
import WaterB  from './../img/water-2.jpg'
import WaterC from './../img/water-3.jpg'
import Waterfall from './../img/waterfall.jpg'
import BlogList from './../components/BlogList'
import QuoteData from './../data/quotes.json'

function Quotes() {
  const Data = QuoteData.posts.sort(() => Math.random() - 0.5)
    return (
      <Container fluid className={classes.Main}>
        
        <div className={classes.HeaderImage + " " + classes.HeaderBlock}>
          <div className={classes.TextPhoto}>
            <Navbar>
              <Container>
                <Navbar.Brand href="/quotes" className={classes.Cursive}>Quotes</Navbar.Brand>
                <Nav className="ms-auto">
                  <Nav.Link><Link to="/quotes/all">All</Link></Nav.Link>
                    <NavDropdown title="Tags" id="basic-nav-dropdown">
                    <NavDropdown.Item ><Link to="/quotes/chess">Chess</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/quotes/death">Death</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/quotes/life">Life</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/quotes/love">Love</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/quotes/travel">Travel</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/quotes/wisdom">Wisdom</Link></NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Container>
            </Navbar>
          </div>
        </div>
        <Container fluid>
        <Container className={classes.QuotesBack}>
          <Container className={classes.Feature}>
            <Row>
              <Col md={6} className="m-0 p-0">
                <div className={classes.ImageBlock}> 
                  <img src={Cloud} alt="Cloud over hills" className=" w-100 d-block center-block mx-auto"/>
                  <div className={classes.QuotePhoto}>
                    <p>I wandered lonely as a Cloud<br></br>That floats on high o'er Vales and Hills,<br></br>
                      When all at once I saw a crowd,<br></br>A host of golden Daffodils;<br></br>Beside the Lake, beneath the trees,
                      <br></br>Fluttering and dancing in the breeze.</p><p>-- William Wordsworth, excerpt from "I wandered lonely as a Cloud"</p>
                    </div>
                </div>
              </Col>
              <Col md={6} className="d-flex align-items-center p-3">
                <Row>
                  <div className={classes.HaikuWrapper}>
                    <div className={classes.Haiku}>
                      <p>From time to time<br></br>The clouds give rest<br></br>To the moon-beholders.</p><p>-- Matsuo Bashō</p>
                    </div>
                  </div>
                  <Col xs={4}>
                    <img src={LeafA} alt="Leaf closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                  <Col xs={4}>
                    <img src={LeafB} alt="Leaf closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                  <Col xs={4}>
                    <img src={LeafC} alt="Leaf closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="d-flex align-items-center p-3">
                <Row>
                  <Col xs={4}>
                    <img src={WaterA} alt="Water closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                  <Col xs={4}>
                    <img src={WaterB} alt="Water closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                  <Col xs={4}>
                    <img src={WaterC} alt="Water closeup" className={classes.Window + " w-100 d-block center-block mx-auto"} />
                  </Col>
                  <div className={classes.HaikuWrapper}>
                    <div className={classes.Haiku}>
                      <p>Blowing from the west<br></br>Fallen leaves gather<br></br>In the east.</p><p>-- Yosa Buson</p>
                    </div>
                  </div>

                </Row>

              </Col>
              <Col md={6} className="m-0 p-0">
                <div className={classes.ImageBlock}> 
                  <img src={Waterfall} alt="Waterfall" className={classes.SidePanel + " w-100 d-block center-block mx-auto"}/>
                  <div className={classes.QuotePhoto}>
                  <p>Water is fluid, soft, and yielding. But water will wear away rock, which is rigid and cannot yield. As a rule, whatever is fluid, soft, and yielding will overcome whatever is rigid and hard. This is another paradox: what is soft is strong.</p><p>-- Laozi</p>
                    </div>
                </div>
              </Col>
              
            </Row>
            
          </Container>
          <Container className={classes.Wrapper}>
            <h1 className="pb-4">All Quotes</h1>
            <BlogList blogs={Data}/>
          </Container>
          </Container>
          
          </Container>
        </Container>

    )
}

export default Quotes