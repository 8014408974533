import { useMemo } from "react";
import PropTypes from "prop-types";
export const DOTS = "...";

function usePagination({ totalCount, pageSize, currentPage }) {
  const range = (start, end) => {
    let length = end - start + 1;
    // Create an array and set the elements in it based on start and end
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationSpan = useMemo(() => {
    const totalPaginationCount = Math.ceil(totalCount / pageSize);
    const firstPageId = 1;
    const lastPageId = totalPaginationCount;
    // Total page numbers is determined where 5 is for firstPage + lastPage + currentPage + two sets of DOTS
    const maxPageNumbers = 6;

    // If the total number of pages is less than the maximum number of items to show, return a range without DOTS
    if (totalPaginationCount <= maxPageNumbers) {
      return range(1, totalPaginationCount);
    }

    // Calculate left and right sibling index to ensure it is between a range of 1 or totalPaginationCount
    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPaginationCount);

    // Do not show dots when at either end of the pagination range
    const showDotsLeft = leftSiblingIndex > 2;
    const showDotsRight = rightSiblingIndex < totalPaginationCount - 2;

    //Both left and right dots to be shown
    if (showDotsLeft && showDotsRight) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageId, DOTS, ...middleRange, DOTS, lastPageId];
    }

    // Show dots only on the right side
    if (!showDotsLeft && showDotsRight) {
      let leftItemCount = 5;
      let leftRange = range(1, leftItemCount);
      return [...leftRange, DOTS, totalPaginationCount];
    }

    // Show dots only on the left side
    if (showDotsLeft && !showDotsRight) {
      let rightItemCount = 5;
      let rightRange = range(
        totalPaginationCount - rightItemCount + 1,
        totalPaginationCount
      );
      return [firstPageId, DOTS, ...rightRange];
    }
  }, [totalCount, pageSize, currentPage]);

  return paginationSpan;
}

usePagination.propTypes = {
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
};

usePagination.defaultProps = {
  totalCount: 0,
  pageSize: 15,
  currentPage: 1,
};

export default usePagination;