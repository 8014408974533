import { Link } from 'react-router-dom'
import classes from './css/Footer.module.scss'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  return (
    <Container fluid className={classes.Wrapper}>
      <footer className={classes.Footer}>
        <div className={classes.Top+ " text-center"}>
          <h5>creative. intuitive. elegant.</h5>
        </div>
        <Container>
          <Row className="pb-2">
            <Col>
            <h4>JD</h4>
              <hr></hr>
            <p>An experienced professional.</p>
            </Col>
            <Col className="me-auto">
              <h4>Explore</h4>
              <hr></hr>
                <p><Link to="/">Home</Link></p>
                <p><Link to="about">About</Link></p>
                <p><Link to="gallery">Gallery</Link></p>
                <p><Link to="quotes">Quotes</Link></p>
            </Col>
            <Col>
              <h4>Ponder</h4>
              <hr></hr>
              <p>"You may learn much more from a game you lose than from a game you win."</p><p className={classes.Quote}>-- José Raúl Capablanca </p>
            </Col>
          </Row>
        </Container>
        <div>
          <p className={classes.Bottom + " text-center"}>© 2022 JD</p>
        </div>
      </footer>
    </Container>
  );
}

export default Footer;