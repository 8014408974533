import React, { useMemo, useState } from 'react'
import Pagination from './Pagination'
import { uid } from 'react-uid'
import classes from "./css/Blogs.module.scss"
import { Link } from 'react-router-dom'

function BlogList({ blogs }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);


  // Get the pagination data based on the user's selections
  const currentPagination = useMemo(() => {
    const firstPageId = (currentPage - 1) * pageSize;
    const lastPageId = firstPageId + pageSize;
    return blogs.slice(firstPageId, lastPageId);
  }, [currentPage, pageSize, blogs]);

  return (
    <div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        pageSize={pageSize}
        onPageSizeOptionChange={(size) => {
          // Get the first listed blog of the previous pageSize's currentPage to show somewhere in the current page of the new page size
          setCurrentPage(1);
          setPageSize(size);
        }}
        totalCount={blogs.length}
      />
      <ul >
        {currentPagination.map((blog) => (
          <li className={classes.Wrapper} key={blog.id}>
            <p variant="body" className={classes.Content}>{blog.content}</p>
            <p className={classes.Author}>-- {blog.author}</p>
            <p className={classes.Tags}>{blog.tags.map((tag) => <Link to={`/quotes/${tag}`} onClick={() => setCurrentPage(1)} key={uid(tag)}>{ tag }</Link>)}</p>
          </li>

        ))}
      </ul>
    </div>
  );
}

export default BlogList;