import { Link } from 'react-router-dom'
import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import classes from './css/Header.module.scss'
import logo from './../img/JDLogo.svg' // with import
import { CgMenuGridR, CgClose } from 'react-icons/cg'

function Header() {
  const [open, setOpen] = useState(false)
  const menuIcon = <CgMenuGridR className={classes.Icon}
    size='40px' color='white' 
    onClick={() => setOpen(!open)}
    />
    const closeIcon = <CgClose className={classes.Icon}
    size='40px' color='black'
    onClick={() => setOpen(!open)}
    />
    
  return (
    <>
      <Navbar className={classes.Position}>
        <Container fluid className="mx-2">
          <Navbar.Brand href="/">
            <img src={logo} width="40" height="40" className={classes.Icon + " d-inline-block align-top"} alt="JD Logo"/>
          </Navbar.Brand>
          {open ? closeIcon : menuIcon}
          {open && 
            <ul className={classes.Menu}>
              <li><Link to="/" onClick={() => setOpen(!open)}>Home</Link></li>
              <li><Link to="about" onClick={() => setOpen(!open)}>About</Link></li>
              <li><Link to="gallery" onClick={() => setOpen(!open)}>Gallery</Link></li>
              <li><Link to="quotes" onClick={() => setOpen(!open)}>Quotes</Link></li>
            </ul>
           }
        </Container>
      </Navbar>
      
    </>
  )
}

export default Header