export const countries = [
  {
    image:"/images/bali-indonesia.jpg",
    title: "Bali, Indonesia",
  },
  {
    image:"/images/bryce-canyon.jpg",
    title: "Bryce Canyon, United States",
  },
  {
    image:"/images/bucegi.jpg",
    title: "Munții Bucegi, Romania",
  },
  {
    image:"/images/cappadocia.jpg",
    title: "Cappadocia, Turkey",
  },
  {
    image:"/images/doi-inthanon.jpg",
    title: "Doi Inthanon, Thailand",
  },
  {
    image:"/images/kathak.jpg",
    title: "Kathak, Indian classical dance",
  },
  {
    image:"/images/khao-sok-national-park.jpg",
    title: "Khao Sok National Park, Thailand",
  },
  {
    image:"/images/koprovsky-stit.jpg",
    title: "Kôprovský štít, Slovakia",
  },
  {
    image:"/images/laguna-lejia.jpg",
    title: "Laguna Lejía, Chile",
  },
  {
    image:"/images/lofoten.jpg",
    title: "Lofoten, Norway",
  },
  {
    image:"/images/lower-antelope-canyon.jpg",
    title: "Lower Antelope Canyon, United States",
  },
  {
    image:"/images/mae-chaem.jpg",
    title: "Mae Chaem, Thailand",
  },
  {
    image:"/images/morraine-lake.jpg",
    title: "Moraine Lake, Canada",
  },
  {
    image:"/images/near-bran-castle.jpg",
    title: "Brașov, Romania",
  },
  {
    image:"/images/niederbauen-chulm.jpg",
    title: "Niederbauen-Chulm, Switzerland",
  },
  {
    image:"/images/reinebringen.jpg",
    title: "Reinebringen, Norway",
  },
  {
    image:"/images/seljalandsfoss.jpg",
    title: "Seljalandsfoss, Iceland",
  },
  {
    image:"/images/south-korea-dragon-vs-tiger.jpg",
    title: "Dragon vs Tiger, South Korea",
  },
  {
    image:"/images/sukasada-bali.jpg",
    title: "Sukasada, Indonesia",
  },
  {
    image:"/images/theyyam.jpg",
    title: "Theyyam dance, India",
  },
  {
    image:"/images/vik.jpg",
    title: "Vik, Iceland",
  },
  {
    image:"/images/yili-xinjiang.jpg",
    title: "Ili Xinjiang, China",
  },
  {
    image:"/images/zhangjiajie.jpg",
    title: "Zhangjiajie, China",
  },
  {
    image:"/images/zhangye-gansu-china.jpg",
    title: "Zhangye, China",
  }
];