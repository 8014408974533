import Particles from "react-tsparticles"
import { loadSlim } from "tsparticles-slim" // loads tsparticles-slim
//import { loadFull } from "tsparticles"; // loads tsparticles
import { useCallback, useMemo } from "react"

// tsParticles Repository: https://github.com/matteobruni/tsparticles
// tsParticles Website: https://particles.js.org/
const ParticlesComponent = (props) => {
  // using useMemo is not mandatory, but it's recommended since this value can be memoized if static
  const options = useMemo(() => {
    // using an empty options object will load the default options, which are static particles with no background and 3px radius, opacity 100%, white color
    // all options can be found here: https://particles.js.org/docs/interfaces/Options_Interfaces_IOptions.IOptions.html
    return {
      "fpsLimit": 60,
      "background": {
          "color": "#f8f8ff"
      },
      "particles": {
        "number": {
          "value": 20,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": ["#799424","#6d5699", "#ccba6d"]
        },
        "shape": {
          "type": ["circle","triangle","square"],
        },
        "opacity": {
          "value": { min: 0.05, max: 0.3 },
          "random": true
        },
         rotate: {
          value: 0,
          random: true,
          direction: "clockwise",
          animation: {
            enable: true,
            speed: { min: 0.05, max: 2 },
            sync: false
          }
        },
        size: {
          value: { min: 10, max: 100 }
        },
        "move": {
          "enable": true,
          "speed": { min: 0.5, max: 1.5 },
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
        },
        "modes": {
          "repulse": {
            "distance": 300,
            "duration": 0.4
          },
        "push": {
            "quantity": 4
        }
        }
      },
      "retina_detect": true
    };
  }, []);

  // useCallback is not mandatory, but it's recommended since this callback can be memoized if static
  const particlesInit = useCallback((engine) => {
    loadSlim(engine);
    // loadFull(engine); // for this sample the slim version is enough, choose whatever you prefer, slim is smaller in size but doesn't have all the plugins and the mouse trail feature
  }, []);

  // setting an id can be useful for identifying the right particles component, this is useful for multiple instances or reusable components
  return <Particles id={props.id} init={particlesInit} options={options} />;
};

export default ParticlesComponent;