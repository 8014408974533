import { Link, useParams } from 'react-router-dom'
import classes from './css/QuotesTags.module.scss'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import React from 'react'
import BlogList from './../components/BlogList'
import QuoteData from './../data/quotes.json'

function QuotesTags() {
  const params = useParams()
  const tag = params.id
  const CapitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  let Data = []
  let Filtered = []

  if (tag === 'all') {
    Data = QuoteData.posts.sort(() => Math.random() - 0.5)
  }
  else {
    Filtered = QuoteData.posts.filter(search => {
      return search.tags.includes(tag)
    })
    Data = Filtered.sort(() => Math.random() - 0.5)
  }

  return (
    <Container fluid className={classes.Main}>
      <div className={classes.HeaderImage + " " + classes.HeaderBlock}>
        <div className={classes.TextPhoto}>
          <Navbar>
            <Container>
              <Navbar.Brand href="/quotes" className={classes.Cursive}>Quotes Home</Navbar.Brand>
              <Nav className="ms-auto">
                <Nav.Link><Link to="/quotes/all">All</Link></Nav.Link>
                  <NavDropdown title="Tags" id="basic-nav-dropdown">
                  <NavDropdown.Item ><Link to="/quotes/chess">Chess</Link></NavDropdown.Item>
                  <NavDropdown.Item><Link to="/quotes/death">Death</Link></NavDropdown.Item>
                  <NavDropdown.Item><Link to="/quotes/life">Life</Link></NavDropdown.Item>
                  <NavDropdown.Item><Link to="/quotes/love">Love</Link></NavDropdown.Item>
                  <NavDropdown.Item><Link to="/quotes/travel">Travel</Link></NavDropdown.Item>
                  <NavDropdown.Item><Link to="/quotes/wisdom">Wisdom</Link></NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>
      <Container className={classes.Wrapper}>
        <h1 className="pb-4">{CapitalizeFirst(tag)} Quotes</h1>
        <BlogList blogs={Data} />
      </Container>
    </Container>

  )
}

export default QuotesTags