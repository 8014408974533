import { Link } from "react-router-dom"
import classes from './css/Home.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Particles from "./../components/Particles"
import About from './../img/about.jpg'
import Gallery from './../img/gallery.jpg'
import Quotes from './../img/quotes.jpg'
import Avatar from './../img/avatarsquare.png'
import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com'

function Home() {
  const [buttonText, setButtonText] = useState('Submit');
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm('service_s4u7zan', 'template_v1l9t4n', form.current, 'sUxS4Lnc3XZ3mpsZh')
      .then((result) => {
        console.log(result.text)
        setButtonText('Message Sent!')
        e.target.reset()
      }, (error) => {
        console.log(error.text)
        setButtonText('Error!')
      })
    }

  return (
    <>
      <Container fluid className={classes.Container}>
      <div className={classes.Title}>
        <h2 className={classes.Job}>Web Developer</h2>
        <h1 className={classes.Name}>Julia Dwight</h1>
        <p className={classes.Desc}>Hi! I'm a developer located in<br></br>London, Ontario.</p>
      </div>
      <div className={classes.Wrapper}>
        <div className={classes.LeftTri}></div>
        <div className={classes.RightTri}></div>
      </div>
      <Container className={classes.Content}>
          <Row>
            <Col md={6} lg={4}>
              <Link to="about">
                <img src={About} alt="Looking into the horizon" className={classes.Photo + " rounded-circle d-block center-block mx-auto"} />
                <h3 className="text-center mx-auto">About</h3>
              </Link>
            </Col>
            <Col md={6} lg={4}>
              <Link to="gallery">
                <img src={Gallery} alt="Snowy mountain peak" className={classes.Photo + " rounded-circle d-block center-block mx-auto"} />
                <h3 className="text-center mx-auto">Gallery</h3>
              </Link>
            </Col>
            <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 0 }}>
              <Link to="quotes">
                <img src={Quotes} alt="Boats on river near mountain" className={classes.Photo + " rounded-circle d-block center-block mx-auto"} />
                <h3 className="text-center mx-auto">Quotes</h3>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className={classes.Subscribe}>
          <Row className="align-items-center">
            <Col md={6}>
              <div className={classes.Speech}>
                <h3>Need help on a project?</h3>
                <h5>Fill in the form below and I'll reach out!</h5>
                <form ref={form} onSubmit={sendEmail}>
                  <input type="email" name="user_email" placeholder="Email Address" />
                  <br></br>
                    <textarea name="message" placeholder="Message" />
                    <br></br>
                  <button type="submit" value="Send">{buttonText}</button>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <img src={Avatar} alt="JD avatar" className={classes.Photo + " d-block center-block mx-auto"} />
            </Col>
        </Row>
        </Container>
      <div className={classes.ParticleWrapper}>
        <Particles className={classes.Particles}/>
      </div>
    </Container>
    </>
  )
}

export default Home